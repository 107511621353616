<div class="row">
  <div class="col">
    <a [routerLink]="['..']">Back to list</a>
  </div>
</div>
<div *ngIf="model && !model.isDeleted">
  <div *ngIf="model" class="row">
    <div class="col-sm">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>General</h5>
          <div class="row">
            <div class="col-sm-4 data-label">
              Sales Order Id
            </div>
            <div class="col-auto">
              {{ model.id }}
            </div>
            <div *ngIf="model.onHold" class="col-auto text-danger">
              On Hold
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              <span *ngIf="model.orderSource === 'wsp-order'">Web Order Number</span>
              <span *ngIf="model.orderSource !== 'wsp-order'">Reference</span>
            </div>
            <div class="col-auto">
              {{ model.referenceNumber }}
            </div>
          </div>
          <div class="row" *ngIf="model.impersonator != null">
            <div class="col-sm-4 data-label">
              Impersonated By
            </div>
            <div class="col-auto">
              {{ model.impersonator }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Order Source
            </div>
            <div class="col-auto">
              {{ model.orderSource }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              PO No.
            </div>
            <div class="col-auto">
              {{ model.poNo }}
            </div>
          </div>

          <div class="row" *ngIf="model.csgRequest">
            <div class="col-sm-4 data-label">
              CSG #
            </div>
            <div class="col-auto">
              <a [routerLink]="" (click)="routeToCSGPage()">{{ model.csgRequest.csgNumber }}</a>
            </div>
          </div>

          <div class="row" *ngIf="model.latestStatus === 'Pricing Exception'">
            <div class="row">
              <div class="col-sm-4 data-label">
                Expected Price
              </div>
              <div class="col-auto">
                <a [routerLink]="" (click)="routeToCSGPage()">{{ model.csgRequest.expectedPrice | currency }}</a>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="model.isSplitOrder">
            <div class="col-sm-4 data-label">
              Split Order
            </div>
            <div class="col-auto">
              <h5><a [routerLink]="['/orders']" [queryParams]="{ 'search': model.referenceNumber }"><i
                    class="fa fa-code-branch"></i></a></h5>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Order Type
            </div>
            <div class="col-auto">
              {{ model.orderType }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              NAV Customer Number
            </div>
            <div class="col-auto">
              {{ model.navCustomerNumber }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Legacy Customer Number
            </div>
            <div class="col-auto">
              {{ model.legacyCustomerNo }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Customer
            </div>
            <div class="col-auto">
              {{ model.customerName }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Customer Type
            </div>
            <div class="col-auto">
              {{ model.customerType }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Web User(Requestor)
            </div>
            <div class="col-auto">
              {{ model.webUser }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Customer GL Number
            </div>
            <div class="col-auto">
              {{ getCustomerGlNum(model.lines) }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              NAV Assembly Numbers
            </div>
            <div class="col-auto">
              {{ getAssemblyNumbers() }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Priority
            </div>
            <div class="col-auto">
              {{ getPriority() }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Comments
            </div>
            <div class="col-auto">
              {{ getRemakeComments() }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Date Added
            </div>
            <div class="col-auto">
              {{ longDateWithSeconds(model.addDate, true) }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Due Date
            </div>
            <div class="col-auto">
              {{ getDueDates() }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Status
            </div>
            <div class="col-auto">
              {{ model.latestStatus }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Posted
            </div>
            <div class="col-auto">
              {{ (model.posted && model.posted !== '') ? calendarDate(model.posted) : '' }}
            </div>
          </div>

          <div class="row"
            *ngIf="model.csgRequest && model.csgRequest.clientReferenceNumber && model.csgRequest.clientReferenceNumber !== ''">
            <div class="col-sm-4 data-label">
              Client Ref. #
            </div>
            <div class="col-auto">
              {{ model.csgRequest.clientReferenceNumber }}
            </div>
          </div>

          <div class="row"
            *ngIf="model.csgRequest && model.csgRequest.externalOrderNumber && model.csgRequest.externalOrderNumber !== ''">
            <div class="col-sm-4 data-label">
              External Order Number
            </div>
            <div class="col-auto">
              {{ model.csgRequest.externalOrderNumber }}
            </div>
          </div>

          <div class="row" *ngIf="model.orderReference1 && model.orderReference1 !== ''">
            <div class="col-sm-4 data-label">
              Order Reference 1
            </div>
            <div class="col-auto">
              {{ model.orderReference1 }}
            </div>
          </div>

          <div class="row" *ngIf="model.orderReference2 && model.orderReference2 !== ''">
            <div class="col-sm-4 data-label">
              Order Reference 2
            </div>
            <div class="col-auto">
              {{ model.orderReference2 }}
            </div>
          </div>

          <div class="row" *ngIf="model.orderReference3 && model.orderReference3 !== ''">
            <div class="col-sm-4 data-label">
              Order Reference 3
            </div>
            <div class="col-auto">
              {{ model.orderReference3 }}
            </div>
          </div>

          <div class="row" *ngIf="model.orderReference4 && model.orderReference4 !== ''">
            <div class="col-sm-4 data-label">
              Order Reference 4
            </div>
            <div class="col-auto">
              {{ model.orderReference4 }}
            </div>
          </div>

          <div class="row" *ngIf="model.orderReference5 && model.orderReference5 !== ''">
            <div class="col-sm-4 data-label">
              Order Reference 5
            </div>
            <div class="col-auto">
              {{ model.orderReference5 }}
            </div>
          </div>          
          <div class="row" *ngIf="model && model.navCustomerNumber">
            <div class="col-sm-4 data-label">
              <a routerLink="/customers/{{model.navCustomerNumber}}/notifications/{{model.id}}"
                routerLinkActive="active">Notifications</a>
            </div>
          </div>
          <br />
          <div>
            <div class="row">
              <div class="col-sm-3">
                <button *ngIf="model && canEditSalesOrder()" class="btn btn-sm btn-primary"
                  (click)="editSalesOrder()">Edit Sales Order</button>
              </div>
              <div class="col-sm-3">
                <button *ngIf="model && canEditEdiFlag()" class="btn btn-sm btn-primary"
                  (click)="updateEdiFlagForSalesOrder()">{{ model.ediFlag ? 'Unset EDI Flag' : 'Set EDI Flag'
                  }}</button>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="model.designRequestId && model.designRequestId !== ''">
            <div class="col-sm-6">
              <a [routerLink]="['/eprequests', model.designRequestId]">View Design Request</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>Shipping</h5>
          <div class="card-text alert alert-info" *ngIf="model.orderSource === 'assembly-order'">
            No shipping, this is an assembly order
          </div>
          <div class="card-text" *ngIf="model.orderSource !== 'assembly-order'">

            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to Company
              </div>
              <div class="col-auto">
                {{ model.shipToCompany }}
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to Contact
              </div>
              <div class="col-auto">
                {{ model.shipToName }}
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to Address
              </div>
              <div class="col-auto">
                {{ model.address }}
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to Address 2
              </div>
              <div class="col-auto">
                {{ model.address2 }}
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to City
              </div>
              <div class="col-auto">
                {{ model.city }}
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to State
              </div>
              <div class="col-auto">
                {{ model.state }}
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to ZIP Code
              </div>
              <div class="col-auto">
                {{ model.postalCode }}
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to Country
              </div>
              <div class="col-auto">
                {{ model.country }}
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Shipping Agent
              </div>
              <div class="col-auto">
                {{ model.shippingAgentCode }}
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Shipping Service Code
              </div>
              <div class="col-auto">
                {{ model.shippingAgentServiceCode }}
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Shipping Service
              </div>
              <div class="col-auto">
                {{ model.shippingAgentDescription }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Email Address
              </div>
              <div class="col-auto">
                {{ model.email }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Consolidated Shipment
              </div>
              <div class="col-auto">
                {{(model.isConsolidated) ? 'Yes' : 'No'}}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Shipments
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-5 data-label">
                    Tracking #
                  </div>
                  <div class="col-3 data-label">
                    Date
                  </div>
                  <div class="col-2 data-label">
                    Carrier
                  </div>
                  <div class="col-2 data-label">
                    PON
                  </div>
                </div>
                <div class="row" *ngIf="noShipments">
                  <div class="col-12">
                    <div class="alert alert-info">
                      No shipments
                    </div>
                  </div>
                </div>
                <div class="row" *ngFor="let shipment of getShipments()">
                  <div class="col-5">
                    <span *ngIf="!shipment.url || shipment.url === ''">{{shipment.trackingNumber}}</span>
                    <a *ngIf="shipment.url && shipment.url !== ''"
                      [attr.href]="shipment.url">{{shipment.trackingNumber}}</a>
                  </div>
                  <div class="col-3">
                    {{getDisplayDate(shipment.trackingNumberDate)}}
                  </div>
                  <div class="col-2">
                    {{shipment.carrier}}
                  </div>
                  <div class="col-2">
                    {{shipment.pon}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Packing Slip
              </div>
              <div class="col-auto">
                {{ model.packingSlipUrl }}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="model" class="row">
    <div class="col-lg">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>Order Lines</h5>
          <div class="btn-group" role="group">
            <button type="button" class="btn btn-primary btn-sm" (click)="selectAll()">Select All</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="selectNone()">Select None</button>
          </div>
          <div class="row mt-1">
            <div class="col">
              <div *ngIf="anySelected()" class="btn-group" role="group">
                <button [attr.disabled]="isPickingRedirectLocation || confirmDeleteOrderLines ? 'disabled' : undefined"
                  type="button" class="btn btn-primary btn-sm" (click)="pickRedirectLocation()">Redirect</button>
                <button
                  [attr.disabled]="isPickingRedirectLocation  || confirmDeleteOrderLines || !canDeleteSalesOrder() ? 'disabled' : undefined"
                  type="button" class="btn btn-danger btn-sm" (click)="toggleDeleteOrderLines()">Delete</button>
              </div>
            </div>
          </div>

          <div class="row mt-1" *ngIf="confirmDeleteOrderLines">
            <div class="col">
              <div class="alert alert-info">Are you sure you want to remove these order lines(s)?</div>
              <div class="row">
                <div class="col-auto data-label">
                  <label for="delete-reason">Reason for delete:</label>
                </div>
                <div class="col-sm">
                  <input id="delete-reason" class="form-control" type="text" [(ngModel)]="deleteReason" />
                </div>
              </div>
              <button [attr.disabled]="(!deleteReason || deleteReason === '' || deleting) ? 'disabled' : undefined"
                class="btn btn-danger mr-1" (click)="deleteOrderLines()">DELETE!</button>
              <button class="btn btn-secondary" (click)="cancelDelete()">Cancel</button>
            </div>
          </div>

          <div class="row mt-1" *ngIf="isPickingRedirectLocation">
            <div class="col-auto">
              <select class="form-control" [(ngModel)]="selectedRedirectorLocation">
                <option *ngFor="let location of locationModel" [value]="location.locationCode2Dig + ' ' + location.locationCode">
                  {{location.locationCode2Dig + ' ' + location.locationCode}}</option>
              </select>
              <br>
              <ng-container *ngIf="isAdmin()">
                <input type="checkbox" class="checkbox checkbox-centered" [(ngModel)]="sendToWps">
                <label class="text-centered">Send To Wps</label>
              </ng-container>
              
              <br>
              <button *ngIf="!redirectInProgress" class="btn btn-primary mr-1" [attr.disabled]="redirectInProgress" (click)="redirect()">Redirect!</button>
              <!-- <div *ngIf="redirectInProgress" class="text-centered"> -->
                <span *ngIf="redirectInProgress" class="fa fa-spinner fa-3x fa-spin" style="padding: 10px;"></span>
              <!-- </div> -->
              <button class="btn btn-secondary" (click)="cancelRedirect()">Cancel</button>
            </div>
          </div>

          <div class="card-text">
            <div class="table-responsive-">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" class="col-auto">Select</th>
                    <th scope="col">NAV Line No.</th>
                    <th scope="col">PON</th>
                    <th scope="col" *ngIf="isRemake()">Remake PON</th>
                    <th scope="col">NAV Item No.</th>
                    <th scope="col">Design ID</th>
                    <th scope="col" class="line-qty">Qty Ordered</th>
                    <th scope="col" class="line-qty">Qty Shipped</th>
                    <th scope="col" class="line-qty">Qty To Ship</th>
                    <th scope="col" class="line-qty">Qty Backordered</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Location</th>
                    <th scope="col">Drop Ship</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr [ngClass]="{'table-primary': viewingLine.lineNumber === line.lineNumber}"
                    *ngFor="let line of sortLineByLineNumber(model.lines)">
                    <td class="col-auto"><input [attr.title]="getLineSelectTooltip(line)"
                        [attr.disabled]="!canSelectLine(line) ? 'disabled' : undefined" type="checkbox"
                        name="selectLine" id="selectLine" [(ngModel)]="line.isSelected"
                        (change)="selectResourceLines(line)"></td>
                    <td>{{ line.lineNumber }}</td>
                    <td>
                      {{ line.pon }}
                      <div *ngIf="line.quantity === line.quantityShipped" class="badge badge-pill badge-success">Posted
                      </div>
                    </td>
                    <td *ngIf="isRemake()">{{ line.remakePon }}</td>
                    <td>{{ line.sku }}</td>
                    <td><a [routerLink]="" (click)="routeToDesignsPage(line)">{{ line.designId }}</a></td>
                    <td class="line-qty">{{ line.quantity }}</td>
                    <td class="line-qty">{{ line.quantityShipped }}</td>
                    <td class="line-qty">{{ line.quantityToShip }}</td>
                    <td class="line-qty">{{ line.quantityBackOrdered }}</td>
                    <td>{{ (line.dueDate && line.dueDate !== '') ? monthDayDate(line.dueDate, true) : 'N/A' }}</td>
                    <td>{{ line.locationCode }}</td>                    
                    <td>{{ (line.isDropShip) ? 'Yes' : 'No'}}</td>
                    <td>
                      <button class="btn-primary" (click)="setViewingLine(line)"
                        *ngIf="model.lines.length > 1">Details</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="model && model.files && model.files.length" class="row">
    <div class="col-lg">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>Order Files</h5>
          <div class="card-text">
            <app-order-files [files]="this.model.files"></app-order-files>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="viewingLine" class="row">
    <div class="col-sm">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title">Product</h5>
          <div class="card-text">
            <div class="row">
              <div class="col-sm-4 data-label">
                NAV Item No.
              </div>
              <div class="col-auto">
                {{ viewingLine.sku }}
              </div>
            </div>
            <div class="row" *ngIf="viewingLine.catalogSku && viewingLine.catalogSku  !== ''">
              <div class="col-sm-4 data-label">
                Catalog Sku
              </div>
              <div class="col-auto">
                {{ viewingLine.catalogSku }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Item Category
              </div>
              <div class="col-auto">
                {{ getProductAttribute(viewingLine).code }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Product Group
              </div>
              <div class="col-auto">
                {{ getProductAttribute(viewingLine).description }}
              </div>
            </div>
            <div class="row" *ngIf="viewingLine.description && viewingLine.description !== ''">
              <div class="col-sm-4 data-label">
                Description
              </div>
              <div class="col-auto">
                {{ viewingLine.description }}
              </div>
            </div>
            <div class="row" *ngIf="viewingLine.description2 && viewingLine.description2 !== ''">
              <div class="col-sm-4 data-label">
                Description 2
              </div>
              <div class="col-auto">
                {{ viewingLine.description2 }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Quantity
              </div>
              <div class="col-auto">
                {{ viewingLine.quantity }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Unit Price
              </div>
              <div class="col-auto">
                {{ viewingLine.unitPrice | currency }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Discounted Unit Price
              </div>
              <div class="col-auto">
                {{ viewingLine.discountedUnitPrice | currency }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Discount Code
              </div>
              <div class="col-auto">
                {{ viewingLine.discountCode }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Discount Percentage
              </div>
              <div class="col-auto">
                {{ viewingLine.discountPercentage || 0 }}%
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Discount Total
              </div>
              <div class="col-auto">
                {{ discountTotal(viewingLine) | currency }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Order Total
              </div>
              <div class="col-auto">
                {{ orderTotal(viewingLine) | currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm" *ngIf="model.orderSource === 'assembly-order'">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title">Image</h5>
          <div class="card-text">
            <div class="row">
              <div class="col">
                <img *ngIf="hasImage" id="designImage" [attr.src]="viewingLine.weiImageURL"
                  (error)="setDefaultImage($event)" width="100%">
                <div *ngIf="!hasImage" id="imageMissing" style="text-align: center">No image available.</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="col-sm" *ngIf="model.orderSource !== 'assembly-order'">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title">Design</h5>
          <div class="card-text">

            <div class="row">
              <div class="col-sm-4 data-label">
                Design Id
              </div>
              <div class="col-auto">
                {{ viewingLine.designId }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Digital Passport
              </div>
              <div class="col-auto">
                <a target="_blank" [attr.href]="viewingLine.digitalPassportUrl">Open in new tab</a>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-6">
                <span class="data-label">Client Image</span><br />
                <span *ngIf="isUrl(viewingLine.clientImageURL)">
                  <img [attr.src]="viewingLine.clientImageURL" width="100%">
                </span>
                <span *ngIf="!isUrl(viewingLine.clientImageURL)">
                  Text 1: {{ model.textbox2Art }}<br />
                  Text 2: {{ model.textbox2Art }}<br />
                  Text 3: {{ model.textbox3Art }}<br />
                </span>
              </div>
              <div class="col-sm-6 data-label">
                WE Image<br />
                <img *ngIf="hasImage" id="designImage" [attr.src]="viewingLine.weiImageURL"
                  (error)="setDefaultImage($event)" width="100%">
                <div *ngIf="!hasImage" id="imageMissing" style="text-align: center">Image not ready.</div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <!-- <button class="btn btn-sm btn-primary" (click)="launchCorelClickOnce()">Open In CorelDraw</button> -->
                <div class="col-sm">
                  <!-- <button class="btn btn-sm btn-primary" (click)="launchCorelClickOnce()">Open In CorelDraw</button> -->
                  <a href={{getCorelClickOnceUrl()}} class=" btn btn-sm btn-primary">Open In CorelDraw</a>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="viewingLine.designValidatedBy">
              <div class="col-sm-6 data-label">
                Design Validated By:
              </div>
              <div class="col-auto">
                {{ viewingLine.designValidatedBy }}
              </div>
            </div>

            <div class="row" *ngIf="viewingLine.designLastValidatedDate">
              <div class="col-sm-6 data-label">
                Design Validated Date:
              </div>
              <div class="col-auto">
                {{ calendarDate(viewingLine.designLastValidatedDate) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm"
      *ngIf="model.orderSource === 'episerver-design-request' && model.designFileInitializationErrors && model.designFileInitializationErrors.length > 0">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body mb-2">
          <h5 class="card-title">Design File Error List</h5>
          <div class="card-text mb-1">
            Please Correct The below Issues and Resubmit
          </div>
          <div class="card-text">
            <div *ngFor="let err of model.designFileInitializationErrors">
              <div class="error-text">{{err}}</div>
            </div>
          </div>

          <button class="btn btn-sm btn-primary" (click)="retryCDRFileInitialization()">Retry Design File Initialization</button>

          <input class="checkbox checkbox-centered" id="overwriteCdr{{i}}" type="checkbox" [(ngModel)]="overwriteCdr"/>
          <label>Overwrite Existing CDR?</label>
        </div>
      </div>
    </div>

    <div class="col-sm" *ngIf="viewingLine.designFileInitializationErrors && viewingLine.designFileInitializationErrors.length > 0">
    <div class="card" style="min-width: 27rem;">
      <div class="card-body mb-2">
        <h5 class="card-title">Design File Error List</h5>
        <div class="card-text mb-1">
          Please Correct The below Issues and Resubmit
        </div>
        <div class="card-text">
          <div *ngFor="let err of viewingLineDesignFileErrors">
            <div class="error-text">{{err}}</div>
          </div>
        </div>

        <button class="btn btn-sm btn-primary" (click)="retryCDRFileInitialization()">Retry Design File Initialization</button>

          <input class="checkbox checkbox-centered" id="overwriteCdr{{i}}" type="checkbox" [(ngModel)]="overwriteCdr"/>
        <label>Overwrite Existing CDR?</label>
      </div>
    </div>
  </div>

    <div *ngIf="HasFinishedGarmentImage()" class="col-sm mb-1">
      <div class="card" style="min-width: 27rem; max-height: 27rem">
        <div class="card-body">
          <h5 class="card-title">Finished Garment Images</h5>
          <div class="card-text">
            <div class="col-sm">
              <div id="carouselControls" class="carousel slide" data-ride="carousel">

                <div class="carousel-inner">
                  <div *ngFor="let image of currentGarmentImages; let i = index" class="carousel-item"
                    [ngClass]="{ 'active': currentGarmentIndex === i }">
                    <img [attr.src]="image.uploadedUrl" width="100%" class="d-block w-100 centered-image">
                  </div>
                </div>

                <div *ngIf="currentGarmentImages && currentGarmentImages.length > 1">
                  <a class="carousel-control-prev" role="button" data-slide="prev" (click)="setGarmentImageIndex(-1)">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" role="button" data-slide="next" (click)="setGarmentImageIndex(1)">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col">
      <div
        *ngIf="viewingLine.nameDropperNames && viewingLine.nameDropperNames !== '' && viewingLine.nameDropperNames !== 'null' && (!viewingLine.names || viewingLine.names.length < 1)"
        class="row">
        <div class="col-lg">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Name Dropper Names</h5>
              <div class="card-text">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Line Name No</th>
                      <th>Line 1 Text</th>
                      <th>Line 2 Text</th>
                      <th>Line 3 Text</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tr class="tr" *ngFor="let name of nameDropperNames(viewingLine)">
                    <td class="td">{{ name.LineNameNo }}</td>
                    <td class="td">{{ name.Line1Text }}</td>
                    <td class="td">{{ name.Line2Text }}</td>
                    <td class="td">{{ name.Line3Text }}</td>
                    <td class="td">{{ name.QtyToProduce }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div
        *ngIf="viewingLine.names && viewingLine.names.length > 0"
        class="row">
        <div class="col-lg">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Name Dropper Names</h5>
              <div class="card-text">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Line Name No</th>
                      <th>Line 1 Text</th>
                      <th>Line 2 Text</th>
                      <th>Line 3 Text</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tr class="tr" *ngFor="let name of viewingLine.names">
                    <td class="td">{{ name.nameNumber }}</td>
                    <td class="td">{{ name.line1 }}</td>
                    <td class="td">{{ name.line2 }}</td>
                    <td class="td">{{ name.line3 }}</td>
                    <td class="td">{{ name.quantity }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body" *ngIf="anyTs()">
          <h5 class="card-title">Tracking System</h5>
          <div class="card-text" *ngIf="!model.isTracked">
            <div class="row">
              <div class="col-sm-4 data-label">
                Tracked
              </div>
              <div class="col-auto">
                <i class="far fa-times-circle text-danger"></i>
              </div>
            </div>
            <div class="row" *ngIf="!model.isTracked && authService.isAdmin()">
              <div class="col">
                <button [attr.disabled]="(model.isTracking || model.reTracked) ? 'disabled' : undefined"
                  class="btn btn-info" (click)="trackOrder()">Track this order</button>
                <div *ngIf="model.reTracked" class="alert alert-info">This order has been tracked.</div>
              </div>
            </div>
          </div>
          <div class="card-text" *ngIf="model.isTracked">
            <div class="row">
              <div class="col-sm-4 data-label">
                Tracked
              </div>
              <div class="col-auto">
                <i class="far fa-check-square text-success"></i>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Tracked Date
              </div>
              <div class="col-auto">
                {{ longDateWithSeconds(formatDateString(model.trackedDate),true) }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Latest Check Point
              </div>
              <div class="col-auto">
                {{ trackingCheckpoint() }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                Checkpoints:
              </div>
              <div class="col-8">
                {{ checkpointNames(model.events) }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 data-label">
                QC Required
              </div>
              <div class="col-auto">
                <!-- <i class="far fa-times-circle text-danger"></i> -->
                <!-- <i class="far fa-check-square text-success"></i> -->
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="anyWps()">
          <h5 class="card-title">WPS</h5>
          <div class="card-text">
            <div class="row">
              <div class="col">
                <a [attr.href]="wpsUrl()">View orders in WPS</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm" *ngIf="model.orderSource === 'nbi-order'">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title">Comments</h5>
          <div class="card-text">
            <div class="row">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Line No.</th>
                    <th scope="col" i18n>Line 1</th>
                    <th scope="col" i18n>Line 2</th>
                    <th scope="col" i18n>Line 3</th>
                    <th scope="col" i18n>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let name of viewingLine.names">
                    <td>
                      {{ name.nameNumber }}
                    </td>
                    <td>
                      {{ name.line1 }}
                    </td>
                    <td>
                      {{ name.line2 }}
                    </td>
                    <td>
                      {{ name.line3 }}
                    </td>
                    <td>
                      {{ name.Quantity }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 <!--  <div *ngIf="model" class="row">
    <div class="col" *ngIf="model.specInstr && model.specInstr !== ''">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>Special Instructions</h5>
          <div class="card-text" [innerHTML]="model.specInstr">
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="model" class="row">
    <div class="col" *ngIf="model.specInstr3D && model.specInstr3D !== ''">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>3D Special Instructions</h5>
          <div class="card-text" [innerHTML]="model.specInstr3D">
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="model" class="row">
    <div class="col" *ngIf="model.specInstrColor && model.specInstrColor !== ''">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>Color Special Instructions</h5>
          <div class="card-text" [innerHTML]="model.specInstrColor">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="model" class="row">
    <div class="col" *ngIf="model.specInstrPrintStitch && model.specInstrPrintStitch !== ''">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>Print Stitch Special Instructions</h5>
          <div class="card-text" [innerHTML]="model.specInstrPrintStitch">
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="row">
    <div class="col-sm" *ngIf="model">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title">Invoices</h5>
          <div class="card-text">
            <div class="alert alert-info" *ngIf="!model.invoices || model.invoices.length === 0">
              No invoices found for this order
            </div>
            <div class="row" *ngIf="model.invoices && model.invoices.length > 0">
              <div class="col-2 data-label">
                Invoice No.
              </div>
              <div class="col-2 data-label">
                Invoice Date
              </div>
              <div class="col-2 data-label">
                Total
              </div>
              <div class="col-2 data-label">
                Amount Due
              </div>
              <div class="col-2 data-label">
                Amount Paid
              </div>
              <div class="col-2 data-label">
                Status
              </div>
            </div>
            <div class="row" *ngFor="let invoice of model.invoices">
              <div class="col-2">
                <a [attr.href]="invoice.pdfDownloadUrl">{{invoice.invoiceNumber}}</a>
              </div>
              <div class="col-2">
                {{invoice.invoiceDate}}
              </div>
              <div class="col-2">
                {{getInvoiceTotal(invoice) | currency }}
              </div>
              <div class="col-2">
                {{invoice.amountDue | currency}}
              </div>
              <div class="col-2">
                {{invoice.amountPaid | currency}}
              </div>
              <div class="col-2">
                {{invoice.status}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="model" class="row">
    <div class="col" *ngIf="model.specInstr && model.specInstr !== ''">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>Special Instructions</h5>
          <div class="card-text" [innerHTML]="model.specInstr"  style="white-space: pre-line;">
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="model" class="row">
    <div class="col" *ngIf="model.specInstrColor && model.specInstrColor !== ''">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>Color Special Instructions</h5>
  
          <div class="card-text" *ngFor="let itemSpecialInstrCol of specialInstrColor">
            <div class="card-text" [innerHTML]="itemSpecialInstrCol"  style="white-space: pre-line;">
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="model" class="row">
    <div class="col" *ngIf="model.specInstr3D && model.specInstr3D !== ''">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>3D Special Instructions</h5>
  
          <div class="card-text" *ngFor="let itemSpecialInstr3D of specInstr3D">
            <div class="card-text" [innerHTML]="itemSpecialInstr3D"  style="white-space: pre-line;">
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="model" class="row">
    <div class="col" *ngIf="model.specInstrPrintStitch && model.specInstrPrintStitch !== ''">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>Print Stitch Special Instructions</h5>
  
          <div class="card-text" *ngFor="let itemSpecialInstrPS of specInstrPrintStitch">
            <div class="card-text" [innerHTML]="itemSpecialInstrPS"  style="white-space: pre-line;">
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="model" class="row">
    <div class="col" *ngIf="model.specInstrLeather && model.specInstrLeather !== ''">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>Leather Special Instructions</h5>
  
          <div class="card-text" *ngFor="let itemSpecialInstrLthr of specInstrLeather">
            <div class="card-text" [innerHTML]="itemSpecialInstrLthr"  style="white-space: pre-line;">
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>

  
  <div class="row">
    <div class="col-sm">
      <div class="card" style="min-width: 27rem;" *ngIf="model && model.events && model.events.length">
        <div class="card-body">
          <h5 class="card-title" i18n>Event Log</h5>
          <div class="table-responsive">
            <table class="table wei-table-striped">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" i18n>Event Name</th>
                  <th scope="col" i18n>Source</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of model.events"
                  [ngClass]="{'exception-row': (item.eventName.includes('exception'))}">
                  <td>
                    {{ calendarDate(item.eventDate) }}
                  </td>
                  <td>
                    {{ item.eventName || 'No Event Name' }}
                  </td>
                  <td>
                    {{ item.source }}
                  </td>
                  <td>
                    <a i18n routerLink="/events/{{ item.id }}">Details</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <small-app-modal id="update-edi-flag-modal">
    <update-edi-flag-modal [model]="model" [ediFlagMessage]="ediFlagButtonText"></update-edi-flag-modal>
  </small-app-modal>
</div>
<div *ngIf="model && model.isDeleted">
  <div *ngIf="model" class="row">
    <div class="col-sm-4">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="deleted-card-title" i18n>Deleted Sales Order</h5>
          <div class="row">
            <div class="col-sm-4 data-label">
              Deleted By
            </div>
            <div class="col-auto">
              {{ model.deletedBy }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Delete Reason
            </div>
            <div class="col-auto">
              {{ model.deleteReason }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Delete Date
            </div>
            <div class="col-auto">
              {{ model.deleteDate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
